import React from "react"
import Seo from "../components/seo"
import { graphql } from "gatsby"

import Button from "../components/button"
import {
    faArrowLeft,
} from "@fortawesome/free-solid-svg-icons"
import Breadcrumb from "../components/breadcrumb"
import { MDXRenderer } from "gatsby-plugin-mdx"


function shortenDate(date) {
    return date.substring(0, date.indexOf("T"))
}
const PostPage = ({ data }) => {

    const post = data.mdx
    const modificationDate = shortenDate(post.parent.mtime)

    return <>
        <Seo title={post.frontmatter.title}
             description={post.frontmatter.description}
             location={post.frontmatter.slug}
             dateModified={modificationDate}
             largeImg={true}/>

        <Breadcrumb pages={[{
            name: "Thomas Winters",
            link: "/"
        }, {
            name: "Posts",
            link: "/posts"
        },
            {
                name: post.frontmatter.title
            }
        ]}/>

        <article itemScope itemType="http://schema.org/Article">

            <Seo title={post.frontmatter.title}
                 description={post.frontmatter.description}
                 location={post.frontmatter.slug}
                 largeImg={true}/>
            <meta itemProp="dateModified" content={modificationDate}/>
            <meta itemProp="publisher" content={data.site.siteMetadata.author}/>
            <meta itemProp="author" content={data.site.siteMetadata.author}/>

            <h1 itemProp="name">{post.frontmatter.title}</h1>


            <div itemProp="articleBody">
                <MDXRenderer>{post.body}</MDXRenderer>

            </div>
        </article>

        <section>
            <Button icon={faArrowLeft}
                    link={"/posts"}
                    local={true}
                    text={"Back to all posts"}/>
        </section>
    </>
}

export default PostPage

export const query = graphql`query ($slug: String!) {
    mdx(fields: {slug: {eq: $slug}}) {
        body
        frontmatter {
            title
            description
            date
            tags
        }        
        parent {
            ... on File {
                mtime
            }
        }
    }
    site {
        siteMetadata {
            author
        }
    }
}
`
