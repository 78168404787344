import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import {
    breadcrumb,
    breadcrumbItem,
    active
} from "./breadcrumb.module.scss"

const Breadcrumb = ({ pages }) => {

    return <nav aria-label="breadcrumb">
        <ol className={breadcrumb}>
            {
                pages.map((p, i) =>
                    (i < pages.length - 1 ?
                            <li className={breadcrumbItem}
                                key={p.name}>
                                <Link to={p.link}>
                                    {p.name}
                                </Link>
                            </li>
                            :
                            <li className={breadcrumbItem + " " + active}
                                aria-current="page"
                                key={p.name}>
                                {p.name}
                            </li>

                    )
                )
            }
        </ol>
    </nav>
}

Breadcrumb.propTypes = {
    pages: PropTypes.array
}
export default Breadcrumb
