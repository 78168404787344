// extracted by mini-css-extract-plugin
export var backgroundColor = "#141821";
export var borderRadius = ".15rem";
export var btn = "button-module--btn--57383";
export var btnIcon = "button-module--btn-icon--3575b";
export var btnLarge = "button-module--btn-large--22132";
export var btnNormal = "button-module--btn-normal--a70fd";
export var btnSmall = "button-module--btn-small--cd4aa";
export var btnText = "button-module--btn-text--ee1bd";
export var fullWidth = "button-module--full-width--ac6a0";
export var fullWidthButton = "button-module--fullWidthButton--104ae";
export var horizontalButton = "button-module--horizontal-button--2088e";
export var maxModalWidth = "48em";
export var modalBackgroundColor = "rgba(20,24,33,.75)";
export var normalWidthButton = "button-module--normalWidthButton--c2b57";
export var primary = "button-module--primary--76ae4";
export var secondary = "button-module--secondary--72bdf";
export var verticalButton = "button-module--vertical-button--c5878";
export var verticalIfWideBtn = "button-module--vertical-if-wide-btn--6ef21";