import {
    btnIcon,
    btnText,
    verticalButton,
    verticalIfWideBtn,
    horizontalButton,
    btn,
    btnLarge,
    btnNormal,
    btnSmall,
    normalWidthButton,
    fullWidthButton,
    primary,
    secondary
} from "./button.module.scss"
import React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"

const getSizeClass = size => {
    switch (size) {
        case "lg":
            return btnLarge
        case "sm":
            return btnSmall
        default:
            return btnNormal
    }
}


const ButtonInternals = ({ icon, text, size, vertical, verticalIfWide, children }) => (
    <div
        className={(vertical ? verticalButton : (verticalIfWide ? verticalIfWideBtn : horizontalButton)) + " " + getSizeClass(size)}>
        {icon && <span className={btnIcon}>
            <FontAwesomeIcon icon={icon} className={"fa-lg fa-fw"}/>
        </span>}
        {text && <span className={btnText}>
            {text}
        </span>}
        {children && <span className={btnText}>
            {children}
        </span>}
    </div>
)
const Button = ({
                    icon,
                    text,
                    link,
                    download,
                    copyToClipboard,
                    type,
                    size,
                    fullWidth,
                    vertical,
                    verticalIfWide,
                    local,
                    click,
                    keyCode,
                    children
                }) => {
    let extraClass = primary
    if (type === "secondary") {
        extraClass = secondary
    }
    let fullWidthClass = normalWidthButton
    if (fullWidth) {
        fullWidthClass = fullWidthButton
    }
    const internals = <ButtonInternals icon={icon} text={text} size={size} vertical={vertical}
                                       verticalIfWide={verticalIfWide} children={children}/>

    function handleKeyDown(event) {
        if (keyCode && event.keycode === keyCode) {
            click()
        }
    }

    if (copyToClipboard) {
        return (
            <span className={btn + " " + extraClass + " " + fullWidthClass}
                  onClick={() => {
                      navigator.clipboard.writeText(copyToClipboard)
                  }}
                  aria-hidden="true"
                  role="button">
                {internals}
            </span>
        )
    }
    if (download) {
        return (
            <a className={btn + " " + extraClass}
               href={link}
               target="_blank"
               rel="noopener noreferrer"
               download>
                {internals}
            </a>
        )
    } else {

        if (click) {
            return <button className={btn + " " + extraClass}
                        onKeyDown={handleKeyDown}
                        onClick={click}>
                {internals}
            </button>
        }

        if (local) {
            return (
                <Link className={btn + " " + extraClass}
                      to={link}>
                    {internals}
                </Link>
            )
        }

        return (
            <a className={btn + " " + extraClass}
               href={link}
               target="_blank"
               rel="noopener noreferrer">
                {internals}
            </a>
        )
    }
}
Button.propTypes = {
    text: PropTypes.string,
    link: PropTypes.string,
    download: PropTypes.bool,
    size: PropTypes.string,
    vertical: PropTypes.bool,
    verticalIfWide: PropTypes.bool,
    local: PropTypes.bool,
    click: PropTypes.func,
    keyCode: PropTypes.number
}
export default Button
